import React, { ChangeEventHandler } from 'react';

export interface TextInputProps {
	id: string;
	text: string;
	onInputChange: ChangeEventHandler;
	size: number;
}

function TextInput(props: TextInputProps) {
	return (
		<div>
			<input
				type="text"
				id={props.id}
				className={'border-2 border-gray-300 border-solid rounded-md p-1'}
				value={props.text}
				onChange={props.onInputChange}
				style={{ width: props.size }}
			/>
		</div>
	);
}

export default TextInput;
