import Select, { OptionsType } from 'react-select';

export interface DropObject {
	value: string;
	label: string;
}

export interface DropProps {
	id: string;
	drops: DropObject[];
	selected: OptionsType<DropObject>;
	placeHolder?: string;
	onSelectChange: (value: OptionsType<DropObject>) => void;
}

function MultiDropdown(props: DropProps) {
	let placeHolder;

	if (props.placeHolder != null) {
		placeHolder = props.placeHolder;
	} else {
		placeHolder = null;
	}

	const customStyles = {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		option: (provided: any, state: any) => ({
			...provided,
			backgroundColor: state.isSelected ? '#4766ff' : 'white',
			'&:hover': {
				backgroundColor: state.isFocused ? 'rgb(71, 102, 255, 0.2)' : 'white',
			},
		}),
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		control: (base: any, state: any) => ({
			...base,
			border: state.isFocused ? '2px #4766ff solid !important' : '2px rgb(204, 204, 204) solid',
			'&:hover': {
				border: state.isFocused ? '2px #4766ff solid !important' : '2px rgb(204, 204, 204) solid',
			},
		}),
	};

	return (
		<div>
			<Select
				id={props.id}
				className="basic-single"
				classNamePrefix="select"
				name="label"
				isMulti
				options={props.drops}
				value={props.selected}
				placeholder={placeHolder}
				onChange={props.onSelectChange}
				styles={customStyles}
			/>
		</div>
	);
}

export default MultiDropdown;
