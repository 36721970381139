// eslint-disable-next-line no-use-before-define
import React from 'react';
import { Route, useHistory } from 'react-router-dom';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import getEnv from './envs';
import Input from './containers/Input/Input';
import Analysis from './containers/Analysis/Analysis';
import Output from './containers/Output/Output';
import SetCurrent from './containers/SetCurrent/SetCurrent';

const Launchpad = React.lazy(() => import('./containers/Launchpad/Launchpad'));

const AppWithRouterAccess = () => {
	const env = getEnv();
	const history = useHistory();
	const onAuthRequired = () => {
		history.push('/');
	};

	const oktaAuth = new OktaAuth({
		issuer: env.issuer,
		clientId: env.clientId,
		redirectUri: env.redirectUri,
		onAuthRequired: { onAuthRequired },
		scopes: env.scopes,
		pkce: true,
	});

	const restoreOriginalUri = async (_oktaAuth, originalUri) => {
		history.replace(toRelativeUrl(originalUri, window.location.origin));
	};

	return (
		<Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
			<SecureRoute exact path="/" component={Launchpad} />
			<SecureRoute exact path="/Input" component={Input} />
			<SecureRoute exact path="/Analysis" component={Analysis} />
			<SecureRoute exact path="/Output" component={Output} />
			<SecureRoute exact path="/SetCurrent" component={SetCurrent} />
			<Route path="/login/callback" component={LoginCallback} />
		</Security>
	);
};

export default AppWithRouterAccess;
