import React, { MouseEventHandler, Dispatch, useState } from 'react';
import DatePicker from 'react-datepicker';
import { delayedChanges, immediateChanges } from '../../constants/data';

export interface ModalSetCurrentProps {
	updateDate: Date;
	setUpdateDate: Dispatch<Date>;
	typeOfUpdate: string;
	setTypeOfUpdate: Dispatch<string>;
	onSubmit: MouseEventHandler;
	onModalClose: MouseEventHandler;
}

export default function ModalSetCurrent(props: ModalSetCurrentProps) {
	const handleUpdateDateChange = (date: Date) => {
		// the date must be in the future to do a delayed changed
		if (date > new Date()) {
			props.setUpdateDate(date);
		}
	};
	const [isDatePickerDisabled, setDatePickerDisabled] = useState(false);

	const handleRadioButtonChange = () => {
		const changeTypeButtons = document.getElementsByName('changeType');
		for (let i = 0; i < changeTypeButtons.length; i += 1) {
			const curButton = changeTypeButtons[i] as HTMLInputElement;
			if (curButton.checked) {
				props.setTypeOfUpdate(curButton.value);
				setDatePickerDisabled(curButton.value === immediateChanges);
			}
		}
	};

	return (
		<>
			<div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
				<div className="relative max-w-full mx-auto my-6">
					{/* content */}
					<div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
						{/* header */}
						<div className="flex items-start justify-between p-5 border-b border-solid rounded-t border-blueGray-200">
							<h3 className="text-2xl font-semibold">
								Please choose between delayed or immediate changes.
							</h3>
						</div>
						{/* body */}
						<div className="grid ml-10 mr-10 sm:gap-4 xl:gap-4 sm:grid-cols-1 xl:grid-cols-2 p-8 text-center">
							<div>
								<div className="text-xl">
									<input
										type="radio"
										name="changeType"
										value={delayedChanges}
										id="delayed"
										onChange={handleRadioButtonChange}
										defaultChecked
									></input>
									<label htmlFor="delayed"> Schedule Changes</label>
								</div>
								<div className="mt-3">Date to schedule changes</div>
								<span className="inline-block">
									{' '}
									<DatePicker
										name="calPicker"
										disabled={isDatePickerDisabled}
										selected={props.updateDate}
										onChange={handleUpdateDateChange}
									/>
								</span>
							</div>
							<div className="text-xl">
								<input
									type="radio"
									name="changeType"
									value={immediateChanges}
									id="immediate"
									onChange={handleRadioButtonChange}
								></input>
								<label htmlFor="delayed"> Perform Changes Now</label>
							</div>
						</div>
						{/* footer */}
						<br />
						<div className="flex items-center justify-end p-6 border-t border-solid rounded-b border-blueGray-200">
							<button
								className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear bg-blue-500 rounded shadow outline-none active:bg-emerald-600 hover:shadow-lg focus:outline-none"
								type="button"
								onClick={props.onSubmit}
							>
								{props.typeOfUpdate}
							</button>
							<button
								className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear bg-blue-500 rounded shadow outline-none active:bg-emerald-600 hover:shadow-lg focus:outline-none"
								type="button"
								onClick={props.onModalClose}
							>
								Close
							</button>
						</div>
					</div>
				</div>
			</div>
			{/* overlay */}
			<div className="fixed inset-0 z-40 bg-black opacity-25"></div>
		</>
	);
}
