import { useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { AccessToken } from '@okta/okta-auth-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
	faCog,
	faSignOutAlt,
	faFileImport,
	faChartBar,
	faGreaterThan,
	faLessThan,
	faFileExport,
	faClipboardList,
} from '@fortawesome/free-solid-svg-icons';

function Sidebar(props: { mainView: string }) {
	const { authState, oktaAuth } = useOktaAuth();
	const [accessToken, setAccessToken] = useState<AccessToken | undefined>(undefined);
	const [userName, setUserInfo] = useState('');

	useEffect(() => {
		if (authState === null || !authState.isAuthenticated) {
			// When user isn't authenticated, forget any user info
			setAccessToken(undefined);
			setUserInfo('');
		} else {
			setAccessToken(authState.accessToken);
			oktaAuth.token.getUserInfo().then((user) => {
				const username = user ? user.name : '';
				setUserInfo(username ?? '');
			});
		}
	}, [accessToken, authState, oktaAuth]); // eslint-disable-line react-hooks/exhaustive-deps
	// Update if authState changes

	const [sidebarClosed, setSidebarClosed] = useState(false);

	return (
		<>
			{/* sidebar */}
			<aside
				id="menu"
				className={`${
					!sidebarClosed
						? 'flex flex-col w-64 min-h-screen p-1 text-blue-700 bg-white filter drop-shadow-md'
						: 'flex flex-col w-24 min-h-screen p-1 text-blue-700 bg-white filter drop-shadow-md'
				}`}
			>
				{!sidebarClosed ? (
					<button
						onClick={() => setSidebarClosed(true)}
						className="w-4 ml-64 text-white bg-blue-700 rounded-r-md"
					>
						<FontAwesomeIcon icon={faLessThan as IconProp} />
					</button>
				) : (
					<button
						onClick={() => setSidebarClosed(false)}
						className="w-4 ml-24 text-white bg-blue-700 rounded-r-md"
					>
						<FontAwesomeIcon icon={faGreaterThan as IconProp} />
					</button>
				)}

				{/* logo */}
				<div className="h-1/4">
					<a href="/" className="flex items-center space-x-1 text-yellow-600">
						<FontAwesomeIcon icon={faCog as IconProp} className="text-2xl" />
						<span className="text-xs font-bold leading-tight">
							Item
							<br />
							Calibration
							<br />
							Tool
						</span>
					</a>
					{!sidebarClosed ? (
						<div className="mt-10 text-lg font-bold leading-tight text-center">
							{userName && (
								<div>
									<p>
										<span className="text-sm font-normal text-black">Logged in as,</span>
										<br />
										{userName}!
									</p>
								</div>
							)}
						</div>
					) : null}
				</div>
				{/* nav */}
				<nav className="space-y-6 text-xl font-bold h-1/2">
					<a
						href="/Input"
						className={`${
							props.mainView === 'Input'
								? 'block py-2.5 px-4 border-l-4 border-blue-700'
								: 'block py-2.5 px-4 border-l-4 border-white'
						}`}
					>
						<FontAwesomeIcon icon={faFileImport as IconProp} className="text-3xl" />{' '}
						{!sidebarClosed ? 'Input' : ''}
					</a>
					<a
						href="/Analysis"
						className={`${
							props.mainView === 'Analysis'
								? 'block py-2.5 px-4 border-l-4 border-blue-700'
								: 'block py-2.5 px-4 border-l-4 border-white'
						}`}
					>
						<FontAwesomeIcon icon={faChartBar as IconProp} className="text-3xl" />{' '}
						{!sidebarClosed ? 'Analysis' : ''}
					</a>
					<a
						href="/Output"
						className={`${
							props.mainView === 'Output'
								? 'block py-2.5 px-4 border-l-4 border-blue-700'
								: 'block py-2.5 px-4 border-l-4 border-white'
						}`}
					>
						<FontAwesomeIcon icon={faFileExport as IconProp} className="text-3xl" />{' '}
						{!sidebarClosed ? 'Output' : ''}
					</a>
					<a
						href="/SetCurrent"
						className={`${
							props.mainView === 'SetCurrent'
								? 'block py-2.5 px-4 border-l-4 border-blue-700'
								: 'block py-2.5 px-4 border-l-4 border-white'
						}`}
					>
						<FontAwesomeIcon icon={faClipboardList as IconProp} className="text-3xl" />{' '}
						{!sidebarClosed ? 'Set Current' : ''}
					</a>
				</nav>
				<div className="flex items-end justify-center h-1/4">
					{!sidebarClosed ? (
						<button
							className="inline-block mb-12 w-28 btnStyle"
							onClick={() => {
								oktaAuth.signOut();
							}}
						>
							<FontAwesomeIcon icon={faSignOutAlt as IconProp} /> Logout
						</button>
					) : null}
				</div>
			</aside>{' '}
		</>
	);
}

export default Sidebar;
