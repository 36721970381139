import React, { ChangeEvent, useState, useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { AccessToken } from '@okta/okta-auth-js';
import './Analysis.scss';
import Swal from 'sweetalert2';
import getEnv from '../../envs';
import TextInput from '../../components/TextInput/TextInput';
import ProgressStepBarAnalysis from '../../components/ProgressStepBarAnalysis/ProgressStepBarAnalysis';
import ProgressStepBarUpdate from '../../components/ProgressStepBarUpdate/ProgressStepBarUpdate';
import Sidebar from '../../components/Sidebar/Sidebar';

function Analysis() {
	const { authState, oktaAuth } = useOktaAuth();
	const [userName, setUserInfo] = useState('');
	const [buttonPressed, setButtonPressed] = useState('');
	const [accessToken, setAccessToken] = useState<AccessToken | undefined>(undefined);

	useEffect(() => {
		if (authState === null || !authState.isAuthenticated) {
			// When user isn't authenticated, forget any user info
			setAccessToken(undefined);
			setUserInfo('');
		} else {
			setAccessToken(authState.accessToken);
			oktaAuth.token.getUserInfo().then((user) => {
				const username = user ? user.given_name : '';
				setUserInfo(username ?? '');
			});
		}
	}, [accessToken, authState, oktaAuth]); // eslint-disable-line react-hooks/exhaustive-deps
	// Update if authState changes

	const getToken = () => {
		let token = '';
		if (!(authState === null || !authState.isAuthenticated)) {
			if (authState.accessToken !== undefined) {
				// eslint-disable-next-line prefer-template
				token = 'Bearer ' + authState.accessToken.accessToken;
			}
		}

		return token;
	};

	let statusInterval: NodeJS.Timeout;
	let statusGettingDataText: string = '';
	let statusUploadingDataText: string = '';
	let statusCompleteText: string = '';
	let statusErrorText: string = '';

	const buttonVerAStep1Clicked = () => {
		setButtonPressed('step1');

		// get the filenames from the files selected
		let pointsMatrixFilename = '';
		let itemMetadataFilename = '';

		if (filesSelected.length !== 2) {
			Swal.fire({
				title: 'Error!',
				text: 'There should be 2 files selected, the earned points matrix and the item metadata files.',
				icon: 'error',
				confirmButtonText: 'Ok',
			});
			return;
			// eslint-disable-next-line no-else-return
		} else {
			filesSelected.forEach((filename, index) => {
				if (filename.indexOf('_Itl.txt') !== -1) {
					itemMetadataFilename = filename;
				} else {
					pointsMatrixFilename = filename;
				}
			});

			// check that step 1 - one of the filenames has "_Itl.txt" and step 2 - check that the filenames are the same without the extensions and "_Itl.txt"
			if (itemMetadataFilename === '') {
				Swal.fire({
					title: 'Error!',
					text: 'An item metadata file must be included in the file selection.',
					icon: 'error',
					confirmButtonText: 'Ok',
				});
				return;
			}

			if (itemMetadataFilename.replace('_Itl.txt', '') !== pointsMatrixFilename.slice(0, -4)) {
				Swal.fire({
					title: 'Error!',
					text: 'The earned points matrix and item metadata files must have the same names.',
					icon: 'error',
					confirmButtonText: 'Ok',
				});
				return;
			}
		}

		const obj = {
			pointsMatrixFilename,
			itemMetadataFilename,
			userName,
		};

		const token = getToken();
		const requestID = new Date().getTime();

		// eslint-disable-next-line prefer-template
		const getDataUrl = getEnv().itemCalibrationBaseURL + 'generateSyntax/' + requestID;

		// we want to start a status check on a 2 sec interval
		statusInterval = setInterval(() => CheckStatus('VerAStep1', token, requestID), 2000);

		SendAnalysisRequest(token, getDataUrl, obj);
	};

	function SendAnalysisRequest(token: string, getDataUrl: string, obj: unknown) {
		if (token !== '') {
			setShowModal(true);
			fetch(getDataUrl, {
				method: 'POST',
				headers: {
					'content-type': 'application/json',
					authorization: token,
				},
				body: JSON.stringify(obj),
			})
				.then((response) => response.text())
				.then((jsonStatus) => {
					// do a try catch here to find out if we're getting a response with json in it, or one without
					// if it doesn't have JSON, it's a server error of some kind
					try {
						const result = JSON.parse(jsonStatus);
						setStatusCode(result.statusCode);
						WriteStatusInfo(result.statusCode, result, result.message);
					} catch (e) {
						setTimeout(() => {
							setStatusCode(99);
							// this would be a server error
							// if it has multiple lines, split it up to get the first line of it
							const statusText = () => {
								return jsonStatus?.split('\n') ? jsonStatus?.split('\n')[0] : jsonStatus;
							};
							WriteStatusInfo(99, '', statusText());
						}, 3000);
					}
				})
				.catch((err) => {
					clearInterval(statusInterval);
					setTimeout(() => {
						setStatusCode(99);
						WriteStatusInfo(99, err, err.message);
					}, 3000);
				});
		} else {
			Swal.fire({
				title: 'Error!',
				text: 'Bad Okta access token.',
				icon: 'error',
				confirmButtonText: 'Ok',
			});
		}
	}

	async function CheckStatus(step: string, token: string, requestID: number) {
		let code = -1;
		let statusToDisplay = '';
		let statusMessage = '';

		// if the statusCode is 99, it means an error has been detected already and the API should not be called again
		if (statusCode === 99) {
			code = 99;
		} else {
			let whichStatus = '';
			if (step === 'VerAStep1' || step === 'VerBStep1' || step === 'VerBStep2') {
				whichStatus = 'analysisVAS1Status/';
			} else if (step === 'VerAStep2') {
				whichStatus = 'analysisVAS2Status/';
			}

			// eslint-disable-next-line prefer-template
			const getStatusUrl = getEnv().itemCalibrationBaseURL + whichStatus + requestID;

			const response = await fetch(getStatusUrl, {
				method: 'GET',
				headers: {
					'content-type': 'application/json',
					authorization: token,
				},
			}).catch((err) => {
				return err;
			});

			try {
				// do a try catch here to find out if we're getting a response with json in it, or one without
				// if it doesn't have JSON, it's a server error of some kind
				const text = await response.text();
				const statusData = JSON.parse(text);
				statusToDisplay = statusData;
				statusMessage = statusData.message;
				code = statusData.statusCode;
			} catch (e) {
				statusMessage = response.message;
				code = 99;
			}
			setStatusCode(code);
		}

		WriteStatusInfo(code, statusToDisplay, statusMessage);

		if (code >= 6) {
			clearInterval(statusInterval);
		}
	}

	function WriteStatusInfo(code: number, statusToDisplay: string, statusMessage: string) {
		statusErrorText = '';
		switch (code) {
			case -1:
				statusErrorText = 'No status could be found.';
				break;
			case 0:
				statusGettingDataText = JSON.stringify(statusToDisplay);
				break;
			case 1:
				statusGettingDataText = JSON.stringify(statusToDisplay);
				break;
			case 2:
				statusGettingDataText = JSON.stringify(statusToDisplay);
				break;
			case 3:
				statusGettingDataText = JSON.stringify(statusToDisplay);
				break;
			case 4:
				statusUploadingDataText = JSON.stringify(statusToDisplay);
				break;
			case 5:
				statusUploadingDataText = JSON.stringify(statusToDisplay);
				break;
			case 6:
				statusCompleteText = JSON.stringify(statusToDisplay);
				break;
			case 7:
				statusErrorText = 'No data could be found for the filter selections.';
				break;
			case 99:
				statusErrorText = 'Error: '.concat(statusMessage);

				// resetting the status code so user can submit again from scratch
				setStatusCode(-1);
				break;
			default:
			// default block statement;
		}

		setStatusMessageGettingData(statusGettingDataText);
		setStatusMessageUploadingData(statusUploadingDataText);
		setStatusMessageComplete(statusCompleteText);
		setStatusMessageError(statusErrorText);

		// eslint-disable-next-line no-console
		console.log(statusToDisplay);
	}

	const buttonVerAStep2Clicked = () => {
		setButtonPressed('step2');

		// get the filenames from the files selected
		let flexmirtOutputFilename = '';
		let itemMetadataFilename = '';

		if (filesSelected.length !== 2) {
			Swal.fire({
				title: 'Error!',
				text: 'There should be 2 files selected, the earned points matrix and the item metadata files.',
				icon: 'error',
				confirmButtonText: 'Ok',
			});
			return;
			// eslint-disable-next-line no-else-return
		} else {
			filesSelected.forEach((filename, index) => {
				if (filename.indexOf('_Itl.txt') !== -1) {
					itemMetadataFilename = filename;
				} else if (filename.indexOf('_VersionA-prm.txt') !== -1) {
					flexmirtOutputFilename = filename;
				}
			});

			// check that step 1 - one of the filenames has "_Itl.txt" and  and step 2 - check that the filenames are the same without the extensions and "_Itl.txt"
			if (itemMetadataFilename === '') {
				Swal.fire({
					title: 'Error!',
					text: 'An item metadata file must be included in the file selection.',
					icon: 'error',
					confirmButtonText: 'Ok',
				});
				return;
			}
			if (flexmirtOutputFilename === '') {
				Swal.fire({
					title: 'Error!',
					text: 'A flexMIRT output file must be included in the file selection.',
					icon: 'error',
					confirmButtonText: 'Ok',
				});
				return;
			}
			if (
				itemMetadataFilename.replace('_Itl.txt', '') !==
				flexmirtOutputFilename.replace('_VersionA-prm.txt', '')
			) {
				Swal.fire({
					title: 'Error!',
					text: 'The flexMIRT output and item metadata files must have the same names.',
					icon: 'error',
					confirmButtonText: 'Ok',
				});
				return;
			}
		}

		const obj = {
			flexmirtOutputFilename,
			itemMetadataFilename,
		};

		const token = getToken();
		const requestID = new Date().getTime();

		// eslint-disable-next-line prefer-template
		const getDataUrl = getEnv().itemCalibrationBaseURL + 'outputUpdate/' + requestID;

		// we want to start a status check on a 2 sec interval
		statusInterval = setInterval(() => CheckStatus('VerAStep2', token, requestID), 2000);

		SendAnalysisRequest(token, getDataUrl, obj);
	};

	const buttonVerB1Step1Clicked = () => {
		setButtonPressed('step1');

		// get the filenames from the files selected
		let pointsMatrixFilename = '';
		let itemMetadataFilename = '';

		if (filesSelected.length !== 2) {
			Swal.fire({
				title: 'Error!',
				text: 'There should be 2 files selected, the earned points matrix and the item metadata files.',
				icon: 'error',
				confirmButtonText: 'Ok',
			});
			return;
			// eslint-disable-next-line no-else-return
		} else {
			filesSelected.forEach((filename, index) => {
				if (filename.indexOf('_Itl.txt') !== -1) {
					itemMetadataFilename = filename;
				} else {
					pointsMatrixFilename = filename;
				}
			});

			// check that step 1 - one of the filenames has "_Itl.txt" and step 2 - check that the filenames are the same without the extensions and "_Itl.txt"
			if (itemMetadataFilename === '') {
				Swal.fire({
					title: 'Error!',
					text: 'An item metadata file must be included in the file selection.',
					icon: 'error',
					confirmButtonText: 'Ok',
				});
				return;
			}

			if (itemMetadataFilename.replace('_Itl.txt', '') !== pointsMatrixFilename.slice(0, -4)) {
				Swal.fire({
					title: 'Error!',
					text: 'The earned points matrix and item metadata files must have the same names.',
					icon: 'error',
					confirmButtonText: 'Ok',
				});
				return;
			}
		}

		const obj = {
			pointsMatrixFilename,
			itemMetadataFilename,
			userName,
		};

		const token = getToken();
		const requestID = new Date().getTime();

		// eslint-disable-next-line prefer-template
		const getDataUrl = getEnv().itemCalibrationBaseURL + 'generateB1Syntax/' + requestID;

		// we want to start a status check on a 2 sec interval
		statusInterval = setInterval(() => CheckStatus('VerBStep1', token, requestID), 2000);

		SendAnalysisRequest(token, getDataUrl, obj);
	};

	const buttonVerB2Step1Clicked = () => {
		setButtonPressed('step1');

		// get the filenames from the files selected
		let pointsMatrixFilename = '';
		let itemMetadataFilename = '';

		if (filesSelected.length !== 2) {
			Swal.fire({
				title: 'Error!',
				text: 'There should be 2 files selected, the earned points matrix and the item metadata files.',
				icon: 'error',
				confirmButtonText: 'Ok',
			});
			return;
			// eslint-disable-next-line no-else-return
		} else {
			filesSelected.forEach((filename, index) => {
				if (filename.indexOf('_Itl.txt') !== -1) {
					itemMetadataFilename = filename;
				} else {
					pointsMatrixFilename = filename;
				}
			});

			// check that step 1 - one of the filenames has "_Itl.txt" and step 2 - check that the filenames are the same without the extensions and "_Itl.txt"
			if (itemMetadataFilename === '') {
				Swal.fire({
					title: 'Error!',
					text: 'An item metadata file must be included in the file selection.',
					icon: 'error',
					confirmButtonText: 'Ok',
				});
				return;
			}

			if (itemMetadataFilename.replace('_Itl.txt', '') !== pointsMatrixFilename.slice(0, -4)) {
				Swal.fire({
					title: 'Error!',
					text: 'The earned points matrix and item metadata files must have the same names.',
					icon: 'error',
					confirmButtonText: 'Ok',
				});
				return;
			}
		}

		if (avgSlope === '') {
			Swal.fire({
				title: 'Error!',
				text: 'An average slope must be entered into the text input box.',
				icon: 'error',
				confirmButtonText: 'Ok',
			});
			return;
		}

		if (isNaN(Number(avgSlope))) {
			Swal.fire({
				title: 'Error!',
				text: 'The average slope value must be a number',
				icon: 'error',
				confirmButtonText: 'Ok',
			});
			return;
		}

		const obj = {
			pointsMatrixFilename,
			itemMetadataFilename,
			userName,
			avgSlope,
		};

		const token = getToken();
		const requestID = new Date().getTime();

		// eslint-disable-next-line prefer-template
		const getDataUrl = getEnv().itemCalibrationBaseURL + 'generateB2Syntax/' + requestID;

		// we want to start a status check on a 2 sec interval
		statusInterval = setInterval(() => CheckStatus('VerBStep2', token, requestID), 2000);

		SendAnalysisRequest(token, getDataUrl, obj);
	};

	// File picker state
	const [filesSelected, setFilesSelected] = useState<string[]>([]);
	const handleChangeFilesSelected = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.files !== null) {
			const files = [];

			for (let i = 0; i < event.target.files.length; i += 1) {
				files.push(event.target.files[i].name.replace('C:\\fakepath\\', ''));
			}

			setFilesSelected(files);

			// enable buttons when selections have been made
			if (files.length === 0) {
				setButtonsDisabled(true);

				document.getElementById('btnVerAStep1')?.classList.remove('btnStyle');
				document.getElementById('btnVerAStep2')?.classList.remove('btnStyle');
				document.getElementById('btnVerB1Step1')?.classList.remove('btnStyle');
				document.getElementById('btnVerB2Step1')?.classList.remove('btnStyle');

				document.getElementById('btnVerAStep1')?.classList.add('btnStyleGray');
				document.getElementById('btnVerAStep2')?.classList.add('btnStyleGray');
				document.getElementById('btnVerB1Step1')?.classList.add('btnStyleGray');
				document.getElementById('btnVerB2Step1')?.classList.add('btnStyleGray');
			} else {
				setButtonsDisabled(false);

				document.getElementById('btnVerAStep1')?.classList.remove('btnStyleGray');
				document.getElementById('btnVerAStep2')?.classList.remove('btnStyleGray');
				document.getElementById('btnVerB1Step1')?.classList.remove('btnStyleGray');
				document.getElementById('btnVerB2Step1')?.classList.remove('btnStyleGray');

				document.getElementById('btnVerAStep1')?.classList.add('btnStyle');
				document.getElementById('btnVerAStep2')?.classList.add('btnStyle');
				document.getElementById('btnVerB1Step1')?.classList.add('btnStyle');
				document.getElementById('btnVerB2Step1')?.classList.add('btnStyle');
			}
		} else {
			Swal.fire({
				title: 'Error!',
				text: 'No files have been selected.',
				icon: 'error',
				confirmButtonText: 'Ok',
			});
		}
	};

	// Average slope input state
	const [avgSlope, setAvgSlope] = useState('');
	const handleChangeAvgSlope = (event: ChangeEvent<HTMLInputElement>) => {
		setAvgSlope(event.target.value);
	};

	// For enabling/disabling the buttons if files have been selected
	const [buttonsDisabled, setButtonsDisabled] = useState(true);

	// modal stuff
	const [showModal, setShowModal] = useState(false);
	function handleProgressClose() {
		setShowModal(false);
		setButtonPressed('');
	}
	const [statusCode, setStatusCode] = useState(-1);
	const [statusMessageGettingData, setStatusMessageGettingData] = useState('');
	const [statusMessageUploadingData, setStatusMessageUploadingData] = useState('');
	const [statusMessageComplete, setStatusMessageComplete] = useState('');
	const [statusMessageError, setStatusMessageError] = useState('');

	return (
		<div className="flex bg-white">
			{/* sidebar */}
			<Sidebar mainView={'Analysis'} />

			{/* content */}
			<div className="flex-grow">
				{showModal && buttonPressed === 'step1' ? (
					<div className="text-center">
						<ProgressStepBarAnalysis
							statusCode={statusCode}
							statusMessageGettingData={statusMessageGettingData}
							statusMessageUploadingData={statusMessageUploadingData}
							statusMessageComplete={statusMessageComplete}
							statusMessageError={statusMessageError}
							onProgressClose={handleProgressClose}
						/>
					</div>
				) : null}

				{showModal && buttonPressed === 'step2' ? (
					<div className="text-center">
						<ProgressStepBarUpdate
							statusCode={statusCode}
							statusMessageGettingData={statusMessageGettingData}
							statusMessageUploadingData={statusMessageUploadingData}
							statusMessageComplete={statusMessageComplete}
							statusMessageError={statusMessageError}
							onProgressClose={handleProgressClose}
						/>
					</div>
				) : null}

				{/* border border-black border-solid */}
				<header className="pl-10 mt-10 text-2xl font-bold text-gray-700">Analysis</header>
				<div className="pl-10 mt-5 text-base">
					Select your files and click on the appropriate button for analysis
				</div>
				<div className="pl-10 mt-5">
					<input type="file" id="filePicker" multiple onChange={handleChangeFilesSelected} />
				</div>

				<div className="grid mt-5 ml-10 mr-10 sm:gap-6 xl:gap-12 sm:grid-cols-1 xl:grid-cols-3 xl:divide-x-2">
					<div>
						{' '}
						{/* Version A */}
						<header className="text-xl font-bold">Version A</header>
						<div className="mt-5 text-base">Step 1</div>
						<div className="mt-5 text-base">
							Generate syntax file and item metadata file will be updated with IRT model sub-data
						</div>
						<button
							className="mt-5 text-center w-28 btnStyleGray"
							id="btnVerAStep1"
							onClick={buttonVerAStep1Clicked}
							disabled={buttonsDisabled}
						>
							Execute Step 1
						</button>
						<div className="mt-5 text-base">Step 2</div>
						<div className="mt-5 text-base">
							Process flexMIRT output file and update item metadata file
						</div>
						<button
							className="mt-5 text-center w-28 btnStyleGray"
							id="btnVerAStep2"
							onClick={buttonVerAStep2Clicked}
							disabled={buttonsDisabled}
						>
							Execute Step 2
						</button>
					</div>
					<div className="xl:pl-5">
						{' '}
						{/* Version B1 */}
						<header className="text-xl font-bold">Version B1</header>
						<div className="mt-5 text-base">Generate syntax file and anchor item PRM file</div>
						<button
							className="mt-5 text-center w-28 btnStyleGray"
							id="btnVerB1Step1"
							onClick={buttonVerB1Step1Clicked}
							disabled={buttonsDisabled}
						>
							Execute Step 1
						</button>
					</div>
					<div className="xl:pl-5">
						{' '}
						{/* Version B2 */}
						<header className="text-xl font-bold">Version B2</header>
						<div className="mt-5 text-base">
							Generate syntax file with anchor item fix statements
						</div>
						<div className="mt-5">
							{/* Might need a new component for this input field or make adjustments to current one used here */}
							<div className="mt-3 text-base">Enter average slope value</div>
							<TextInput
								id={'txtAvgSlope'}
								text={avgSlope}
								onInputChange={handleChangeAvgSlope}
								size={55}
							/>
						</div>
						<button
							className="mt-5 text-center w-28 btnStyleGray"
							id="btnVerB2Step1"
							onClick={buttonVerB2Step1Clicked}
							disabled={buttonsDisabled}
						>
							Execute Step 1
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Analysis;
