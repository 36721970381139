// Note: This file is used for setting all environment variables for all environments.
// eslint-disable-next-line
const ENV = window.APP_ENV?.DEPLOYMENT_ENV ?? 'localhost';
// const ENV = window.ITEM_CALIBRATION_ENV.DEPLOYMENT_ENV;

const envs = {
	localhost: {
		issuer: 'https://dev-73067812.okta.com/oauth2/default',
		clientId: '0oa1mjdvsoHVq18A05d7',
		redirectUri: 'http://localhost:3000/login/callback',
		scopes: ['openid', 'profile', 'email'],
		itemCalibrationBaseURL: 'http://localhost:8080/v1.0/itemcalibration/',
	},
	dev: {
		issuer: 'https://weldnorthed.okta.com/oauth2/default',
		clientId: '0oa4eisji3ZEMdMrp4x7',
		scopes: ['openid', 'profile', 'email'],
		redirectUri:
			'https://item-calibration-dev.supplemental-nonprod.imaginelearning.com/login/callback',
		itemCalibrationBaseURL:
			'https://api-item-calibration-dev.supplemental-nonprod.imaginelearning.com/v1.0/itemcalibration/',
	},
	qa: {
		issuer: 'https://weldnorthed.okta.com/oauth2/default',
		clientId: '0oa4oiy2up9Gxe9aZ4x7',
		scopes: ['openid', 'profile', 'email'],
		redirectUri:
			'https://item-calibration-qa.supplemental-nonprod.imaginelearning.com/login/callback',
		itemCalibrationBaseURL:
			'https://api-item-calibration-qa.supplemental-nonprod.imaginelearning.com/v1.0/itemcalibration/',
	},
	staging: {
		issuer: 'https://weldnorthed.okta.com/oauth2/default',
		clientId: '0oa4oj7ijj6sXFQft4x7',
		scopes: ['openid', 'profile', 'email'],
		redirectUri:
			'https://item-calibration-staging.supplemental-nonprod.imaginelearning.com/login/callback',
		itemCalibrationBaseURL:
			'https://api-item-calibration-staging.supplemental-nonprod.imaginelearning.com/v1.0/itemcalibration/',
	},
	prod: {
		issuer: 'https://weldnorthed.okta.com/oauth2/default',
		clientId: '0oa6b9lc52LEInmib4x7',
		scopes: ['openid', 'profile', 'email'],
		redirectUri:
			'https://item-calibration-prod.supplemental-prod.imaginelearning.com/login/callback',
		itemCalibrationBaseURL:
			'https://api-item-calibration-prod.supplemental-prod.imaginelearning.com/v1.0/itemcalibration/',
	},
};

export default function getEnv() {
	return envs[ENV];
}
