import CreateableSelect from 'react-select/creatable';
import { ActionMeta } from 'react-select';

export interface DropObject {
	value: string;
	label: string;
}

export interface EditDropProps {
	id: string;
	drops: DropObject[];
	selected: DropObject;
	placeHolder?: string;
	isLoading: boolean;
	onSelectChange: (obj: DropObject | null, actionMeta: ActionMeta<DropObject>) => void;
	onInputChange: (newValue: string) => void;
}

function EditableDropdown(props: EditDropProps) {
	let placeHolder = null;
	const emptyObject: DropObject = { value: '', label: '' };
	let val = emptyObject.value;

	// react-select has a weird thing of not recognizing an empty object {} to show
	// the placeholder value. This and the statement in the value property of the
	// Select tag below is a solution to get the placeholder to show initially and
	// not cause an error in the component story.
	if (props.selected) {
		val = props.selected.value;
	}

	if (props.placeHolder != null) {
		placeHolder = props.placeHolder;
	}

	const customStyles = {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		option: (provided: any, state: any) => ({
			...provided,
			// eslint-disable-next-line no-nested-ternary
			backgroundColor: state.isSelected ? '#4766ff' : 'white',
			'&:hover': {
				// eslint-disable-next-line no-nested-ternary
				backgroundColor: state.isFocused || state.isSelected ? 'rgb(71, 102, 255, 0.2)' : 'white',
				color: state.isSelected || state.isFocused ? 'black' : 'white',
			},
		}),
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		control: (base: any, state: any) => ({
			...base,
			border: state.isFocused ? '2px #4766ff solid !important' : '2px rgb(204, 204, 204) solid',
			'&:hover': {
				border: state.isFocused ? '2px #4766ff solid !important' : '2px rgb(204, 204, 204) solid',
			},
		}),
	};

	return (
		<div>
			<CreateableSelect
				isClearable
				id={props.id}
				className="basic-single"
				classNamePrefix="select"
				options={props.drops}
				value={val !== emptyObject.value ? props.selected : null}
				placeholder={placeHolder}
				onChange={props.onSelectChange}
				onCreateOption={props.onInputChange}
				styles={customStyles}
				isDisabled={props.isLoading}
				isLoading={props.isLoading}
			/>
		</div>
	);
}

export default EditableDropdown;
