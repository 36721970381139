import { MouseEventHandler } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
	faCheck,
	faCircle,
	faFileUpload,
	faDatabase,
	faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';

export interface ProgressProps {
	statusCode: number;
	statusMessageGettingData: string;
	statusMessageUploadingData: string;
	statusMessageComplete: string;
	statusMessageError: string;
	onProgressClose: MouseEventHandler;
}

function ProgressStepBarSetCurrentPreview(props: ProgressProps) {
	const statusMessageGettingData =
		props.statusMessageGettingData !== '' ? JSON.parse(props.statusMessageGettingData) : null;
	const statusMessageUploadingData =
		props.statusMessageUploadingData !== '' ? JSON.parse(props.statusMessageUploadingData) : null;
	const statusMessageComplete =
		props.statusMessageComplete !== '' ? JSON.parse(props.statusMessageComplete) : null;

	return (
		<>
			<div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
				<div className="relative w-3/4 max-w-full mx-auto my-6">
					{/* content */}
					<div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
						{/* header */}
						<div className="flex items-start justify-between p-5 border-b border-solid rounded-t border-blueGray-200">
							<h3 className="text-3xl font-semibold">Where's my data?</h3>
							{props.statusCode >= 6 || props.statusCode === -1 ? (
								<button
									className="inline-block ml-3 text-center w-28 btnStyle"
									type="button"
									onClick={props.onProgressClose}
								>
									Close
								</button>
							) : null}
						</div>
						<div className="flex w-full mt-10 ml-auto mr-auto">
							<div className="w-1/3 p-2 text-xl text-center border-r-2">
								<span className="text-3xl fa-layers fa-fw">
									{props.statusCode === -1 ? (
										<>
											<FontAwesomeIcon icon={faCircle as IconProp} color="gray" />
											<FontAwesomeIcon icon={faDatabase as IconProp} inverse transform="shrink-6" />
										</>
									) : null}
									{props.statusCode > -1 && props.statusCode < 7 ? (
										<FontAwesomeIcon icon={faCircle as IconProp} color="green" />
									) : null}
									{props.statusCode >= 0 && props.statusCode < 4 ? (
										<FontAwesomeIcon
											icon={faDatabase as IconProp}
											pulse
											inverse
											transform="shrink-6"
										/>
									) : null}
									{props.statusCode >= 4 && props.statusCode < 7 ? (
										<FontAwesomeIcon
											icon={faCheck as IconProp}
											color="white"
											transform="shrink-6"
										/>
									) : null}
									{props.statusCode >= 7 ? (
										<FontAwesomeIcon icon={faTimesCircle as IconProp} color="red" />
									) : null}
								</span>{' '}
								Getting Data
							</div>

							<div className="w-1/3 p-2 text-xl text-center border-r-2">
								<span className="text-3xl fa-layers fa-fw">
									{props.statusCode <= 3 ? (
										<>
											<FontAwesomeIcon icon={faCircle as IconProp} color="gray" />
											<FontAwesomeIcon
												icon={faFileUpload as IconProp}
												inverse
												transform="shrink-6"
											/>
										</>
									) : null}
									{props.statusCode >= 4 && props.statusCode < 7 ? (
										<FontAwesomeIcon icon={faCircle as IconProp} color="green" />
									) : null}
									{props.statusCode >= 4 && props.statusCode < 5 ? (
										<FontAwesomeIcon
											icon={faFileUpload as IconProp}
											pulse
											inverse
											transform="shrink-6"
										/>
									) : null}
									{props.statusCode >= 5 && props.statusCode < 7 ? (
										<FontAwesomeIcon
											icon={faCheck as IconProp}
											color="white"
											transform="shrink-6"
										/>
									) : null}
									{props.statusCode >= 7 ? (
										<FontAwesomeIcon icon={faTimesCircle as IconProp} color="red" />
									) : null}
								</span>{' '}
								Uploading Files
							</div>

							<div className="w-1/3 p-2 text-xl text-center">
								<span className="text-3xl fa-layers fa-fw">
									{props.statusCode < 6 ? (
										<>
											<FontAwesomeIcon icon={faCircle as IconProp} color="gray" />
											<FontAwesomeIcon icon={faCheck as IconProp} inverse transform="shrink-6" />
										</>
									) : null}
									{props.statusCode >= 6 && props.statusCode < 7 ? (
										<>
											<FontAwesomeIcon icon={faCircle as IconProp} color="green" />
											<FontAwesomeIcon icon={faCheck as IconProp} inverse transform="shrink-6" />
										</>
									) : null}
									{props.statusCode >= 7 ? (
										<FontAwesomeIcon icon={faTimesCircle as IconProp} color="red" />
									) : null}
								</span>
								Complete
							</div>
						</div>
						<div className="flex mb-10 text-left">
							<div className="w-1/3 p-4 border-r-2">
								{statusMessageGettingData?.setCurrentCriteria ? (
									<div>
										<p className="mb-3 text-lg font-bold text-center text-md">
											Getting Data from Snowflake...
										</p>
										<p className="mb-5">The following item criteria was selected:</p>
										<p>
											<span className="font-bold">ITL File uploaded:</span>{' '}
											{statusMessageGettingData.setCurrentCriteria.itlFile}{' '}
										</p>
										<p>
											<span className="font-bold">IRT Scales:</span>{' '}
											{statusMessageGettingData.setCurrentCriteria.irtScales}{' '}
										</p>
										<p>
											<span className="font-bold">Calibration Set:</span>{' '}
											{statusMessageGettingData.setCurrentCriteria.calibrationSet}{' '}
										</p>
									</div>
								) : (
									<div>
										<p className="mb-3 text-lg font-bold text-center text-md">
											{statusMessageGettingData?.statusCode === 0
												? `Request submitted`
												: statusMessageGettingData?.statusDescription}
										</p>
									</div>
								)}
							</div>
							<div className="w-1/3 p-4 border-r-2">
								{statusMessageUploadingData ? (
									<div>
										<p className="mb-3 text-lg font-bold text-center text-md">
											Uploading files to Box...
										</p>
										<p>
											<span className="font-bold">Folder Name:</span>{' '}
											{statusMessageUploadingData.folderName}{' '}
										</p>
										<p>
											<span className="font-bold">Item Count:</span>{' '}
											{statusMessageUploadingData.itemCount}
										</p>
									</div>
								) : null}
							</div>
							<div className="w-1/3 p-4">
								{statusMessageComplete ? (
									<div>
										<p className="mb-3 text-lg font-bold text-center text-md">
											File upload is complete!
										</p>
										<p className="mb-3">
											{statusMessageComplete.filesCreated.length.toString()} file was uploaded.
										</p>
										<p>File: {statusMessageComplete.filesCreated[0].fileName}</p>
									</div>
								) : null}
							</div>
						</div>
						{/* footer */}
						<div className="flex justify-center p-6 text-xl font-bold text-center text-red-500 border-t border-solid rounded-b border-blueGray-200">
							{props.statusMessageError ? <div>{props.statusMessageError}</div> : null}
						</div>
					</div>
				</div>
			</div>
			<div className="fixed inset-0 z-40 bg-black opacity-25"></div>
		</>
	);
}

export default ProgressStepBarSetCurrentPreview;
