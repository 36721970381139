import React, { ChangeEventHandler } from 'react';

export interface LabelInputLabelProps {
	label1: string;
	label2: string;
	inputID: string;
	time: string;
	onInputChange: ChangeEventHandler;
}

function LabelInputLabel(props: LabelInputLabelProps) {
	return (
		<div>
			{props.label1}
			<input
				type="text"
				id={props.inputID}
				className="w-14 border border-gray-300 border-solid rounded-md p-1"
				maxLength={3}
				value={props.time}
				onChange={props.onInputChange}
			/>{' '}
			{props.label2}
		</div>
	);
}

export default LabelInputLabel;
