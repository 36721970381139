import React, { Suspense } from 'react';
import './App.scss';
import AppWithRouterAccess from './AppWithRouterAccess';

function App() {
	return (
		<div className="App">
			<Suspense fallback={<div>Loading...</div>}>
				<AppWithRouterAccess />
			</Suspense>
		</div>
	);
}

export default App;
