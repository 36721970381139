import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './Calendar.scss';

export interface CalProps {
	id: string;
	fromDate: string;
	toDate: string;
	onFromChange: (date: Date) => void;
	onToChange: (date: Date) => void;
}

function Calendar(props: CalProps) {
	const fromDate = new Date(props.fromDate);
	const toDate = new Date(props.toDate);

	return (
		<div id={props.id} className="inline-block">
			<span className="inline-block">
				{' '}
				{/* border border-gray-300 border-solid p-1 rounded-md */}
				<DatePicker selected={fromDate} onChange={props.onFromChange} />
			</span>
			<span className="mr-3 ml-3">-</span>
			<span className="inline-block">
				{' '}
				{/* border border-gray-300 border-solid p-1 rounded-md */}
				<DatePicker selected={toDate} onChange={props.onToChange} />
			</span>
		</div>
	);
}

export default Calendar;
