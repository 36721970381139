// Sets up all of the various options for the dropdowns and buttons
// data can be used consistently throughout the calibration tool
// so establish here to avoid repetition
export const delayedChanges = 'Schedule changes';
export const immediateChanges = 'Perform changes now';

export const subjects = [
	{ label: 'ELA', value: '1' },
	{ label: 'Math', value: '2' },
	{ label: 'Science', value: '3' },
	{ label: 'History', value: '4' },
];

export const products = [
	{ label: 'All Products', value: 'all' },
	{ label: 'CAT', value: '-1' },
	{ label: 'Galileo', value: '0' },
];

export const grades = [
	{ label: 'All Grades', value: 'all' },
	{ label: 'Kindergarten', value: 'K' },
	{ label: '1st Grade', value: '1' },
	{ label: '2nd Grade', value: '2' },
	{ label: '3rd Grade', value: '3' },
	{ label: '4th Grade', value: '4' },
	{ label: '5th Grade', value: '5' },
	{ label: '6th Grade', value: '6' },
	{ label: '7th Grade', value: '7' },
	{ label: '8th Grade', value: '8' },
	{ label: '9th Grade', value: '9' },
	{ label: '10th Grade', value: '10' },
	{ label: '11th Grade', value: '11' },
	{ label: '12th Grade', value: '12' },
];

export const galileoItemTypes = [
	{ label: 'All Item Types', value: 'all' },
	{ label: 'Multiple Choice', value: '1' },
	{ label: 'True/False', value: '2' },
	{ label: 'Yes/No', value: '3' },
	{ label: 'Short Answer', value: '4' },
	{ label: 'Open Response', value: '5' },
	{ label: 'Submit Work Sample', value: '6' },
	{ label: 'Group Instruction', value: '7' },
	{ label: 'Unknown', value: '8' },
	{ label: 'Media', value: '9' },
	{ label: 'Complex Media Reading Speed', value: '10' },
	{ label: 'Complex Media Audio', value: '11' },
	{ label: 'Selectable Text', value: '12' },
	{ label: 'Word Segmentation', value: '13' },
	{ label: 'Rating Scale', value: '14' },
	{ label: 'Multi-Part', value: '15' },
	{ label: 'Sequencing', value: '16' },
	{ label: 'Expanded Selected Response', value: '17' },
	{ label: 'Customized Technology Enhanced', value: '18' },
	{ label: 'Equation', value: '19' },
	{ label: 'ItemLogic', value: '20' },
	{ label: 'Selectable Labels', value: '21' },
	{ label: 'Classifying', value: '22' },
	{ label: 'Drag and Drop Labels', value: '23' },
	{ label: 'Dropdown', value: '24' },
];

// commented out item types have commas in them and will need to be handled differently.
export const catItemTypes = [
	{ label: 'All Item Types', value: 'all' },
	{ label: 'chemistryessay', value: 'chemistryessay' },
	{ label: 'choicematrix', value: 'choicematrix' },
	{ label: 'classification', value: 'classification' },
	// { label: 'classification,mcq', value: 'classification,mcq' },
	{ label: 'clozeassociation', value: 'clozeassociation' },
	{ label: 'clozedropdown', value: 'clozedropdown' },
	{ label: 'clozetext', value: 'clozetext' },
	{ label: 'formulaV2', value: 'formulaV2' },
	// { label: 'formulaV2,formulaV2', value: 'formulaV2,formulaV2' },
	// { label: 'formulaV2,formulaV2,formulaV2', value: 'formulaV2,formulaV2,formulaV2' },
	{ label: 'graphplotting', value: 'graphplotting' },
	{ label: 'hotspot', value: 'hotspot' },
	{ label: 'imageclozeassociationV2', value: 'imageclozeassociationV2' },
	{ label: 'imageclozedropdown', value: 'imageclozedropdown' },
	{ label: 'mcq', value: 'mcq' },
	// { label: 'mcq,formulaV2,formulaV2', value: 'mcq,formulaV2,formulaV2' },
	// { label: 'mcq,mcq', value: 'mcq,mcq' },
	// { label: 'mcq,tokenhighlight', value: 'mcq,tokenhighlight' },
	{ label: 'numberlineplot', value: 'numberlineplot' },
	{ label: 'orderlist', value: 'orderlist' },
	{ label: 'simplechart', value: 'simplechart' },
	// { label: 'simpleshading,mcq', value: 'simpleshading,mcq' },
	{ label: 'tokenhighlight', value: 'tokenhighlight' },
];

export const status = [
	{ label: 'Operational', value: '0' },
	{ label: 'Field Testing', value: '1' },
	{ label: 'Benched', value: '2' },
];

export const language = [
	{ label: 'English', value: '0' },
	{ label: 'Spanish', value: '1' },
];

export const delimiters = [
	{ label: 'Comma', value: ',' },
	{ label: 'Tab', value: 'char(9)' },
];

export const responseTypes = [
	{ label: 'Complete', value: 'Complete' },
	{ label: 'Incomplete', value: 'Incomplete' },
	{ label: 'Invalidated_Reset', value: 'Invalidated_Reset' },
];
